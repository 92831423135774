export default {
  "menu_index": 'Ana Sayfa',
  'menu_about': 'Hakkımızda',
  'menu_team': 'Profesyonel Ekip',
  'menu_business': 'İş Alanı',
  'menu_case': 'Vaka Gönderimi',
  'menu_info': 'Yasal Bilgiler',
  'menu_success': 'Başarı Hikayeleri',
  'menu_language': 'Dil Değiştir',
  'Integrity': 'Dürüstlük',
  'major': 'Ana',
  'responsibility': 'Sorumluluk',
  'efficiency': 'Verimlilik',
  'index_intro1': 'Müşterilerimize',
  'index_intro2': 'dayanan tam bir yasal hizmet yelpazesi sunuyoruz',
  'index_intro2_orange': 'Profesyonellik',
  'index_intro3_orange': 'verimlilik',
  'index_intro3_white': ' ve',
  'index_intro3_orange2': ' yenilikçilik.',
  'elite_professional': 'Elit Profesyonel Hukuk Bürosu',
  'get_in_touch': 'Bizimle İletişime Geçin',
  'about_sub': 'HAKKIMIZDA',
  'law_name': 'KIRKLAND&ELLIS LLP Hukuk Bürosu',
  'about_intro1': 'Avukatlarımızdan oluşan ekibimiz, dolandırıcılık, varlık takibi ve kurtarma davalarını ele alma konusunda deneyimlidir, müşterilerimize dolandırıcılık, yalan veya dürüstlüğün bir sonucu olarak kaybedilen varlıkları ve parayı kurtarmalarında yardımcı oluruz. Varlıkları takip etmek için etkili stratejiler geliştiriyoruz.',
  'about_intro2': 'Özellikle, hisse fonu yatırım dolandırıcılığı, kripto para dolandırıcılığı, ilişki dolandırıcılığı, yarı zamanlı iş dolandırıcılığı ve daha fazlası da dahil olmak üzere siberle ilgili suçlarla uğraşıyoruz.',
  'about_intro3': 'Özellikle, fon dolandırıcılığına, para zimmetine, sosyal dolandırıcılığa ve yarı zamanlı suçlara bağlı bilgisayar suçlarıyla ilgileniyoruz.',
  'year': 'Yıl',
  'established': 'Kuruluş',
  'number_of_customers_served': 'Hizmet Verilen Müşteri Sayısı',
  'service_case': 'Hizmet Durumu',
  'people': 'Kişi',
  'senior_consultant': 'Kıdemli Danışman',
  'professional_body': 'Profesyonel Kuruluş',
  'team_sub': 'EKİP',
  'anti_fraud_information': 'Sahtekarlık Bilgileri',
  'anti_sub': 'Haberler ve Bilgiler',
  'more': 'DAHA FAZLA',
  'swiper_text1': 'Dürüstlük, profesyonellik, sorumluluk, verimlilik',
  'swiper_text2': 'KIRKLAND&ELLIS LLP Hukuk Bürosu,',
  'swiper_text3': 'yüksek kaliteli ve profesyonel bir hukuk bürosu oluşturma taahhüdündedir',
  'professional_team': 'Profesyonel Ekip',
  'business_tip': 'KIRKLAND&ELLIS LLP Hukuk Bürosu için dürüstlük ve güvenilirlik, çalışkanlık ve sorumluluk, pragmatizm ve kanunlara uyum sağlama ve hedeflere ulaşma sürekli bir takiptir',
  'scope1': 'Yatırım Dolandırıcılığı',
  'scope2': 'Ekonomik Anlaşmazlıklar',
  'scope3': 'Kurumsal Hukuki İşler',
  'scope4': 'Özel Avukat',
  'scope5': 'Finansal Dava',
  'scope6': 'Rekabet Hukuku',
  'scope7': 'Borç Tahsilatı',
  'scope8': 'Diğer İşler',
  'case_consultation': 'Dava Danışmanlığı',
  'case_inquiry': 'Dava Soruşturması',
  'consultation_sub': 'İlerlememiz için görüşleriniz itici güçtür',
  'case_type': 'Dava Türü',
  'select_scam_type': 'Dolandırıcılık türünü seçin',
  'your_case': 'Sizin Durumunuz',
  'please_enter_feedback': 'Lütfen durum geri bildiriminizi girin',
  'your_phone': 'Cep telefonunuz',
  'please_enter_your_phone': 'Lütfen telefon numaranızı girin',
  'submit_click': 'Bir tıklamayla gönder',
  'please_enter_phone': 'Lütfen telefon numarasını girin',
  'search': 'Arama',
  'no_relevant': 'İlgili bir yanıt bulunamadı',
  'success_title': 'Klasik başarı hikayeleri',
  'bot_tele': 'Telefon',
  'bot_address': 'Adres',
  'bot_cooperation': 'İşbirliği Yetkilendirme Ajansı',
  'quick_links': 'Hızlı Bağlantılar',
  'CFTC': 'Commodity and Futures Trading Commission (CFTC) dahil olmak üzere uluslararası finansal düzenleyiciler',
  'FINRA': 'FINRA (FINRA)',
  'FINMA': 'İsviçre Finansal Piyasa Denetleme Otoritesi (FINMA)',
  'FCA': 'Birleşik Krallık Finansal Davranış Otoritesi (FCA)',
  'HKMA': 'Hong Kong Para Otoritesi (HKMA)',
  'CFPB': 'Tüketici Finansal Koruma Bürosu (CFPB)',
  'case_option1': 'İnternet Bağış Dolandırıcılığı',
  'case_option2': 'İnternet Hisse Fonu Yatırım Dolandırıcılığı',
  'case_option3': 'Kripto Para Dolandırıcılığı',
  "case_option4": "aşk dolandırıcılığı",
  "case_option5": "e-ticaret dolandırıcılığı",
  "case_option6": "yarı zamanlı dolandırıcılık",
  "case_option7": "Diğer dolandırıcılıklar",
  "case_detail": "Vaka detayları",
  "reply_message": "Yanıt mesajı",
  "submission_time": "Gönderim zamanı",
  "home_service": "Müşteri Hizmetleri"
}