export default {
  "menu_index": 'Index',
  'menu_about': 'Über uns',
  'menu_team': 'Professionelles Team',
  'menu_business': 'Geschäftsbereich',
  'menu_case': 'Fall einreichen',
  'menu_info': 'Rechtliche Informationen',
  'menu_success': 'Erfolgsfall',
  'menu_language': 'Sprachumschaltung',
  'Integrity': 'Integrität',
  'major': 'Hauptfach',
  'responsibility': 'Verantwortlichkeit',
  'efficiency': 'Effizienz',
  'index_intro1': 'Wir bieten unseren Kunden',
  'index_intro2': 'eine breite Palette von Rechtsdienstleistungen basierend auf',
  'index_intro2_orange': 'Professionalität',
  'index_intro3_orange': 'Effizienz',
  'index_intro3_white': ' und',
  'index_intro3_orange2': ' Innovation.',
  'elite_professional': 'Elite Professionelle Anwaltskanzlei',
  'get_in_touch': 'Kontaktieren Sie uns',
  'about_sub': 'ÜBER UNS',
  'law_name': 'Anwaltskanzlei KIRKLAND&ELLIS LLP',
  'about_intro1': ' Unser Team von Anwälten ist erfahren im Umgang mit Betrug, Vermögensnachverfolgungs- und Wiederherstellungsfällen und unterstützt unsere Kunden dabei, Vermögenswerte und Gelder zurückzugewinnen, die aufgrund von Betrug, Lügen oder Unaufrichtigkeit verloren gegangen sind. Wir entwickeln effektive Strategien zur Nachverfolgung von Vermögenswerten.',
  'about_intro2': ' Wir sind spezialisiert auf die Behandlung von Cyberkriminalität, einschließlich Betrug bei Aktienfondsinvestitionen, Kryptowährungsbetrug, Beziehungsbetrug, Nebenjobbetrug und mehr.',
  'about_intro3': ' Insbesondere befassen wir uns mit Computerstraftaten im Zusammenhang mit Fondsmissbrauch, Unterschlagung von Geldern, Sozialbetrug und Teilzeitkriminalität.',
  'year': 'Jahr',
  'established': 'Gegründet',
  'number_of_customers_served': 'Anzahl der betreuten Kunden',
  'service_case': 'Servicefall',
  'people': 'Menschen',
  'senior_consultant': 'Seniorberater',
  'professional_body': 'Berufsverband',
  'team_sub': 'TEAM',
  'anti_fraud_information': 'Anti-Betrugs-Informationen',
  'anti_sub': 'Nachrichten und Informationen',
  'more': 'MEHR',
  'swiper_text1': 'Integrität, Professionalität, Verantwortlichkeit, Effizienz',
  'swiper_text2': ' Die Anwaltskanzlei KIRKLAND&ELLIS LLP ist bestrebt,',
  'swiper_text3': ' Eine qualitativ hochwertige und professionelle Anwaltskanzlei aufzubauen',
  'professional_team': 'Professionelles Team',
  'business_tip': ' Ehrlichkeit und Vertrauenswürdigkeit, Fleiß und Gewissenhaftigkeit sind die Grundlagen der Anwaltskanzlei KIRKLAND&ELLIS LLP; pragmatisch und gesetzeskonform zu sein und Ziele zu erreichen, sind das unermüdliche Streben der Anwaltskanzlei KIRKLAND&ELLIS LLP',
  'scope1': 'Investitionsbetrug',
  'scope2': 'Wirtschaftliche Streitigkeiten',
  'scope3': 'Unternehmensrechtliche Angelegenheiten',
  'scope4': 'Privatanwalt',
  'scope5': 'Finanzrechtsstreitigkeiten',
  'scope6': 'Kartellrecht',
  'scope7': 'Schuldeneintreibung',
  'scope8': 'Sonstiges Geschäft',
  'case_consultation': 'Fallberatung',
  'case_inquiry': 'Fallanfrage',
  'consultation_sub': 'Ihre Meinung ist der Antrieb für uns, voranzukommen',
  'case_type': 'Falltyp',
  'select_scam_type': 'Betrugstyp auswählen',
  'your_case': 'Ihr Fall',
  'please_enter_feedback': 'Bitte geben Sie Ihr Fallfeedback ein',
  'your_phone': 'Ihre Mobiltelefonnummer',
  'please_enter_your_phone': 'Bitte geben Sie Ihre Telefonnummer ein',
  'submit_click': 'Mit einem Klick einreichen',
  'please_enter_phone': 'Bitte geben Sie die Telefonnummer ein',
  'search': 'Suche',
  'no_relevant': 'Keine relevanten Antworten gefunden',
  'success_title': 'Klassische Erfolgsgeschichten',
  'bot_tele': 'Telefon',
  'bot_address': 'Adresse',
  'bot_cooperation': 'Kooperationsberechtigte Agentur',
  'quick_links': 'Schnelllinks',
  'CFTC': 'Internationale Finanzaufsichtsbehörden einschließlich der Commodity and Futures Trading Commission (CFTC)',
  'FINRA': 'FINRA (FINRA)',
  'FINMA': 'Schweizerische Finanzmarktaufsichtsbehörde (FINMA)',
  'FCA': 'UK Financial Conduct Authority (FCA)',
  'HKMA': 'Hongkonger Währungsbehörde (HKMA)',
  'CFPB': 'Consumer Financial Protection Bureau (CFPB)',
  'case_option1': 'Internet-Fundraising-Betrug',
  'case_option2': 'Betrug bei Internet-Aktienfondsinvestitionen',
  'case_option3': 'Kryptowährungsbetrug',
  'case_option4': 'Liebesbetrug',
  'case_option5': 'E-Commerce-Betrug',
  'case_option6': 'Teilzeitbetrug',
  'case_option7': 'Andere Betrügereien',
  'case_detail': 'Fallangaben',
  'reply_message': 'Antwortnachricht',
  'submission_time': 'Einreichungszeit',
  ["home_service"]: "Kundenservice",
}