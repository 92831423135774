export default {
  "menu_index": "หน้าแรก",
  "menu_about": "เกี่ยวกับเรา",
  "menu_team": "ทีมงานมืออาชีพ",
  "menu_business": "ขอบเขตธุรกิจ",
  "menu_case": "การส่งมอบเคส",
  "menu_info": "ข้อมูลทางกฎหมาย",
  "menu_success": "กรณีสำเร็จ",
  "menu_language": "สลับภาษา",
  "Integrity": "ความซื่อสัตย์",
  "major": "สำคัญ",
  "responsibility": "ความรับผิดชอบ",
  "efficiency": "ประสิทธิภาพ",
  "index_intro1": "เรากำลังให้บริการแก่ลูกค้าของเรา",
  "index_intro2": "ด้วยบริการทางกฎหมายที่ครอบคลุมทั้งหมดซึ่งขึ้นอยู่กับ",
  "index_intro2_orange": "ความเชี่ยวชาญ",
  "index_intro3_orange": "ประสิทธิภาพ",
  "index_intro3_white": "และ",
  "index_intro3_orange2": "นวัตกรรม",
  "elite_professional": "สำนักงานกฎหมายที่มีความเชี่ยวชาญแห่งชาติ",
  "get_in_touch": "ติดต่อเรา",
  "about_sub": "เกี่ยวกับเรา",
  "law_name": "สำนักงานกฎหมาย KIRKLAND&ELLIS LLP",
  "about_intro1": "ทีมของเราทำมืออย่างชำนาญในการจัดการกรณีฉ้อโกงการติดตามและการกู้คืนสินทรัพย์ ช่วยลูกค้าของเราในการกู้คืนสินทรัพย์และเงินที่สูญเสียเนื่องจากการฉ้อโกงการ๊อก, การ๊อก, หรือความไม่ซื่อสัตย์ เราพัฒนากลยุทธ์ที่มีประสิทธิภาพในการติดตามสินทรัพย์",
  "about_intro2": "เราเชี่ยวชาญในการจัดการกับการละเมิดทางไซเบอร์ที่เกี่ยวข้อง เช่น การ๊อกการลงทุนในกองทุนร่วม, การ๊อกสกุลเงินดิจิทัล, การ๊อกความสัมพันธ์, การ๊อกงานพาร์ทไทม์ และอื่น ๆ",
  "about_intro3": "โดยเฉพาะอย่างยิ่งเราจัดการกับอาชญากรรมคอมพิวเตอร์ที่เกี่ยวข้องกับฉ้อโกงการ๊อกเงินการ๊อกเงิน, ฉ้อโกงสังคมและอาชญากรรมแบบพาร์ทไทม์",
  "year": "ปี",
  "established": "ก่อตั้ง",
  "number_of_customers_served": "จำนวนลูกค้าที่บริการ",
  "service_case": "กรณีบริการ",
  "people": "คน",
  "senior_consultant": "ที่ปรึกษาชาวสูงอายุ",
  "professional_body": "องค์กรที่เชี่ยวชาญ",
  "team_sub": "ทีมงาน",
  "anti_fraud_information": "ข้อมูลต้านการฉ้อโกง",
  "anti_sub": "ข่าวและข้อมูล",
  "more": "เพิ่มเติม",
  "swiper_text1": "ความซื่อสัตย์, ความเชี่ยวชาญ, ความรับผิดชอบ, ประสิทธิภาพ",
  "swiper_text2": "สำนักงานทนายความ KIRKLAND&ELLIS LLP มุ่งมั่นที่จะ",
  "swiper_text3": "สร้างสำนักงานทนายความที่มีคุณภาพและมืออาชีพ",
  "professional_team": "ทีมงานมืออาชีพ",
  "business_tip": "ความซื่อสัตย์และน่าเชื่อถือ ความขยันและการรับผิดชอบเป็นรากฐานของสำนักงานทนายความ KIRKLAND&ELLIS LLP; การเป็นจริงใจและเชื่อฟังก์ชันกฎหมายและการบรรลุเป้าหมายเป็นการตามหาในทนายความ KIRKLAND&ELLIS LLP",
  "scope1": "โกงการลงทุน",
  "scope2": "ข้อพิพาททางเศรษฐกิจ",
  "scope3": "กฎหมายขององค์กร",
  "scope4": "ทนายส่วนตัว",
  "scope5": "การฟ้องคดีทางการเงิน",
  "scope6": "กฎหมายความเป็นจริง",
  "scope7": "การเรียกเก็บหนี้",
  "scope8": "ธุรกิจอื่น ๆ",
  "case_consultation": "การปรึกษากรณี",
  "case_inquiry": "สอบถามกรณี",
  "consultation_sub": "ความคิดเห็นของคุณคือแรงผูกพันของเราในการก้าวหน้า",
  "case_type": "ประเภทของเคส",
  "select_scam_type": "เลือกประเภทของการโกง",
  "your_case": "เคสของคุณ",
  "please_enter_feedback": "โปรดใส่ข้อเสนอแนะของคุณในเคส",
  "your_phone": "หมายเลขโทรศัพท์มือถือของคุณ",
  "please_enter_your_phone": "โปรดระบุหมายเลขโทรศัพท์ของคุณ",
  "submit_click": "ส่งด้วยคลิกเดียว",
  "please_enter_phone": "โปรดป้อนหมายเลขโทรศัพท์",
  "search": "ค้นหา",
  "no_relevant": "ไม่พบการตอบกลับที่เกี่ยวข้อง",
  "success_title": "เรื่องราวความสำเร็จคลาสสิก",
  "bot_tele": "โทรศัพท์",
  "bot_address": "ที่อยู่",
  "bot_cooperation": "สำนักงานอนุญาตการร่วมมือ",
  "quick_links": "ลิงก์ด่วน",
  "CFTC": "หน่วยงานควบคุมการเงินระหว่างประเทศรวมถึงคณะกรรมการการซื้อขายสัญญาซื้อขาย (CFTC)",
  "FINRA": "FINRA (FINRA)",
  "FINMA": "หน่วยงานการกำกับตลาดการเงินสวิส (FINMA)",
  "FCA": "หน่วยงานควบคุมการเงินของสหราชอาณาจักร (FCA)",
  "HKMA": "หน่วยงานกึ่งราชการฮ่องกง (HKMA)",
  "CFPB": "สำนักงานคุ้มครองผู้บริโภคทางการเงิน (CFPB)",
  "case_option1": "โกงการระดมทุนผ่านอินเทอร์เน็ต",
  "case_option2": "การโกงการลงทุนในกองทุนหุ้นผ่านอินเทอร์เน็ต",
  "case_option3": "การโกงสกุลเงินดิจิทัล",
  "case_option4": "โกงรัก",
  "case_option5": "การโกงการค้าออนไลน์",
  "case_option6": "โกงงานพาร์ทไทม์",
  "case_option7": "การโกงอื่นๆ",
  "case_detail": "รายละเอียดเคส",
  "reply_message": "ข้อความตอบกลับ",
  "submission_time": "เวลาส่ง",
  "home_service": "บริการลูกค้า"
}