import requestor from "@/utils/requestor"

export default {
  async getConfig() {
    return await requestor._get('/common/getConfig')
  },
  async getService() {
    return await requestor._get('/common/getService')
  },
  async getLanguage() {
    return await requestor._get('/common/getLanguage')
  },
  async getTeamList(data) {
    return await requestor._post('/common/getTeam', data)
  },
  async getTeamDetail(data) {
    return await requestor._post('/common/getTeamDetail', data)
  },
  async getInfoList(data) {
    return await requestor._post('/common/getInformation', data)
  },
  async getInfoDetail(data) {
    return await requestor._post('/common/getInformationDetail', data)
  },
  async getCaseList(data) {
    return await requestor._post('/common/getCase', data)
  },
  async getCaseDetail(data) {
    return await requestor._post('/common/getCaseDetail', data)
  },
  async submitCase(data) {
    return await requestor._post('/case/submit', data)
  },
  async searchCase(data) {
    return await requestor._post('/case/query', data)
  },
  async getBanner(data) {
    return await requestor._post('/common/getBanner', data)
  },
}