export default {
  "menu_index": "Индекс",
  "menu_about": "О нас",
  "menu_team": "Профессиональная команда",
  "menu_business": "Бизнес сфера",
  "menu_case": "Подача заявки",
  "menu_info": "Юридическая информация",
  "menu_success": "Успешные случаи",
  "menu_language": "Переключение языка",
  "Integrity": "Интегритет",
  "major": "Основной",
  "responsibility": "Ответственность",
  "efficiency": "Эффективность",
  "index_intro1": "Мы предоставляем нашим клиентам",
  "index_intro2": "полный спектр юридических услуг на основе",
  "index_intro2_orange": "Профессионализма",
  "index_intro3_orange": "эффективности",
  "index_intro3_white": " и",
  "index_intro3_orange2": " инноваций.",
  "elite_professional": "Элитная профессиональная юридическая фирма",
  "get_in_touch": "Свяжитесь с нами",
  "about_sub": "О НАС",
  "law_name": "Юридическая фирма KIRKLAND&ELLIS LLP",
  "about_intro1": "Наша команда адвокатов имеет опыт в расследовании случаев мошенничества, отслеживания и возврата активов, помогая нашим клиентам восстановить утраченные активы и деньги в результате мошенничества, лжи или недобросовестности. Мы разрабатываем эффективные стратегии для отслеживания активов.",
  "about_intro2": "Мы специализируемся на борьбе с киберпреступлениями, включая мошенничества в сфере инвестиций в акции, мошенничества с криптовалютой, мошенничества в отношениях, мошенничества с временной работой и др.",
  "about_intro3": "В частности, мы занимаемся компьютерными преступлениями, связанными с финансовым мошенничеством, растратой денег, социальным мошенничеством и преступлениями с неполной занятостью.",
  "year": "Год",
  "established": "Основан",
  "number_of_customers_served": "Количество обслуженных клиентов",
  "service_case": "Служебный случай",
  "people": "Люди",
  "senior_consultant": "Старший консультант",
  "professional_body": "Профессиональное тело",
  "team_sub": "КОМАНДА",
  "anti_fraud_information": "Анти-мошенническая информация",
  "anti_sub": "Новости и информация",
  "more": "Подробнее",
  "swiper_text1": "Интегритет, профессионализм, ответственность, эффективность",
  "swiper_text2": "Юридические фирмы KIRKLAND&ELLIS LLP стремятся",
  "swiper_text3": "Создать качественную и профессиональную юридическую фирму",
  "professional_team": "Профессиональная команда",
  "business_tip": "Честность и надежность, прилежание и добросовестность являются основами Юридической фирмы KIRKLAND&ELLIS LLP; прагматичность и законопослушание, достижение целей - неутомимая цель Юридической фирмы KIRKLAND&ELLIS LLP",
  "scope1": "Инвестиционное мошенничество",
  "scope2": "Экономические споры",
  "scope3": "Корпоративные юридические вопросы",
  "scope4": "Частный адвокат",
  "scope5": "Финансовые судебные разбирательства",
  "scope6": "Антимонопольное право",
  "scope7": "Взыскание долгов",
  "scope8": "Другой бизнес",
  "case_consultation": "Консультация по делу",
  "case_inquiry": "Запрос дела",
  "consultation_sub": "Ваше мнение - это движущая сила для нас вперёд",
  "case_type": "Тип дела",
  "select_scam_type": "Выберите тип мошенничества",
  "your_case": "Ваше дело",
  "please_enter_feedback": "Пожалуйста, введите отзыв о вашем случае",
  "your_phone": "Ваш мобильный телефон",
  "please_enter_your_phone": "Пожалуйста, введите ваш номер телефона",
  "submit_click": "Отправить одним нажатием",
  "please_enter_phone": "Пожалуйста, введите номер телефона",
  "search": "поиск",
  "no_relevant": "Соответствующий ответ не найден",
  "success_title": "Классические истории успеха",
  "bot_tele": "Телефон",
  "bot_address": "Адрес",
  "bot_cooperation": "Агентство сотрудничества",
  "quick_links": "Быстрые ссылки",
  "CFTC": "Международные финансовые регуляторы, включая Комиссию по срочной торговле товарами и фьючерсами (CFTC)",
  "FINRA": "FINRA (FINRA)",
  "FINMA": "Швейцарская финансовая рыночная служба (FINMA)",
  "FCA": "Финансовый кондуктовый орган Великобритании (FCA)",
  "HKMA": "Гонконгская валютно-кредитная служба (HKMA)",
  "CFPB": "Бюро защиты потребителей финансовых услуг (CFPB)",
  "case_option1": "Мошенничество в интернет-сборе средств",
  "case_option2": "Мошенничество с инвестициями в интернет-акции",
  "case_option3": "Мошенничество с криптовалютой",
  "case_option4": "Мошенничество в любви",
  "case_option5": "Мошенничество в электронной коммерции",
  "case_option6": "Мошенничество с неполной занятостью",
  "case_option7": "Другие виды мошенничества",
  "case_detail": "Подробности дела",
  "reply_message": "Сообщение в ответ",
  "submission_time": "Время подачи",
  "home_service": "Служба поддержки клиентов"
}