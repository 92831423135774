<template>
  <div class="bottommenu-container">
    <div class="info-box">
      <div class="info-col a">
        <div class="info-col-top">
          <img :src="configInfo.site_logo" alt="" />
          <span>KIRKLAND&ELLIS LLP</span>
        </div>
        <div class="info-col-bot">
          <p class="bot-row">Telephone: {{ configInfo.phone }}</p>
          <p class="bot-row">Address: {{ configInfo.address }}
          </p>
          <!-- <p class="bot-row">Swizerland: Bellerivestrasse 17, 8008 Zürich</p>
          <p class="bot-row">
            HongKong: Citic Tower, Tim Mei Avenue, Central, Hong Kong
          </p>
          <p class="bot-row">U.K: 22 Upper Ground, London SE1 9PD</p> -->
        </div>
      </div>
      <div class="info-col">
        <div class="info-col-top">Quick Links</div>
        <div class="info-col-bot">
          <p class="bot-row" @click="routerChange('/index')">
            &gt;Index
          </p>
          <p class="bot-row" @click="routerChange('/about')">
            &gt;About Us
          </p>
          <p class="bot-row" @click="routerChange('/team')">
            &gt;Professional team
          </p>
          <p class="bot-row" @click="routerChange('/business')">
            &gt;Business Scope
          </p>
          <p class="bot-row" @click="routerChange('/case')">
            &gt;Case submission
          </p>
          <p class="bot-row" @click="routerChange('/news')">
            &gt;Legal Information
          </p>
          <p class="bot-row" @click="routerChange('/success-case')">
            &gt;Success case
          </p>
        </div>
      </div>
      <div class="info-col">
        <div class="info-col-top">Cooperation authorization agency</div>
        <div class="info-col-bot">
          <p class="bot-row">&gt;nternational financial regulators including the Commodity and Futures Trading Commission （CFTC）</p>
          <p class="bot-row">&gt;FINRA（FINRA）</p>
          <p class="bot-row">&gt;Swiss Financial Market Supervisory Authority（FINMA）</p>
          <p class="bot-row">&gt;UK Financial Conduct Authority（FCA）</p>
          <p class="bot-row">&gt;Hong Kong Monetary Authority（HKMA）</p>
          <p class="bot-row">&gt;Consumer Financial Protection Bureau（CFPB）</p>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="copy-right">
      Copyright @ 2024 KIRKLAND&ELLIS LLP. All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configInfo: "",
    };
  },
  async created() {
    await this.getConfig();
  },
  methods: {
    async getConfig() {
      const res = await this.$api.getConfig();
      if (res.code == 200) {
        this.configInfo = res.data;
      }
    },
    async routerChange(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottommenu-container {
  background: #162133;
  width: 100%;
  height: 420px;
  padding-top: 75px;
  padding-bottom: 20px;
  .info-box {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .info-col {
      &-top {
        display: flex;
        align-items: center;
        color: #fff;
        height: 90px;
        img {
          width: 90px;
          margin-right: 5px;
        }
      }
      &-bot {
        font-size: 14px;
        color: #5f6a7d;
        .bot-row {
          line-height: 2;
          padding: 5px;
          padding-left: 0;
          cursor: pointer;
          white-space: pre;
        }
      }
    }
    .a {
      width: 290px;
      .info-col-top {
        display: flex;
        align-items: center;
        font-size: 19px;
      }
      .info-col-bot {
        .bot-row {
          cursor: unset;
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: #ddd;
    margin-top: 20px;
  }
  .copy-right {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #ddd;
  }
}

@media (max-width: 600px) {
  .bottommenu-container {
    width: 100%;
    height: auto;
    margin-top: 50px;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      .info-col {
        &-bot {
          .bot-row {
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
    .line {
    width: 100%;
    height: 1px;
    background: #ddd;
    margin-top: 20px;
  }
  .copy-right {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #ddd;
  }
  }
}
</style>