export default {
  "menu_index": 'Index',
  'menu_about': 'About Us',
  'menu_team': 'Professional team',
  'menu_business': 'Business Scope',
  'menu_case': 'Case submission',
  'menu_info': 'Legal Information',
  'menu_success': 'Success case',
  'menu_language': 'Language switch',
  'Integrity': 'Integrity',
  'major': 'major',
  'responsibility': 'responsibility',
  'efficiency': 'efficiency',
  'index_intro1': 'We are providing our clients',
  'index_intro2': 'with a full range of legal services based on',
  'index_intro2_orange': 'Professionalism',
  'index_intro3_orange': 'efficiency',
  'index_intro3_white': ' and',
  'index_intro3_orange2': '  innovation.',
  'elite_professional': 'Elite Professional Law Firm',
  'get_in_touch': 'Contact us',
  'about_sub': 'ABOUT US',
  'law_name': 'KIRKLAND&ELLIS LLP',
  'about_intro1': ' Our team of lawyers is experienced in handling fraud, asset tracing and recovery cases, assisting our clients to recover assets and money lost as a result of fraud, lies or dishonesty.  We develop effective strategies to trace assets.',
  'about_intro2': ' We specialise in dealing with cyber-related crimes, including share fund investment scams, cryptocurrency scams, relationship scams, part-time job scams and more.',
  'about_intro3': ' In particular, we deal with computer crimes related to fund fraud, embezzlement of money, social fraud and part-time crime.',
  'year': 'Year',
  'established': 'Established',
  'number_of_customers_served': 'Number of customers served',
  'service_case': 'Service Case',
  'people': 'People',
  'senior_consultant': 'Senior consultant',
  'professional_body': 'Professional body',
  'team_sub': 'TEAM',
  'anti_fraud_information': 'Anti-fraud Information',
  'anti_sub': 'News and information',
  'more': 'MORE',
  'swiper_text1': 'Integrity,professional,Responsibility, Efficiency',
  'swiper_text2': ' The Law Offices of KIRKLAND&ELLIS LLP is committed to',
  'swiper_text3': ' Build a high-quality and professional law firm',
  'professional_team': 'Professional team',
  'business_tip': ' Honesty and trustworthiness, diligence and conscientiousness are the foundations of KIRKLAND&ELLIS LLP; being pragmatic and law-abiding and achieving goals are the unremitting pursuit of KIRKLAND&ELLIS LLP ',
  'scope1': 'investment Scam',
  'scope2': 'Economic Disputes',
  'scope3': 'Corporate Legal affairs',
  'scope4': 'Private Attorney',
  'scope5': 'financial Litigation',
  'scope6': 'Antitrust Law',
  'scope7': 'Debt Collection',
  'scope8': 'Other Business',
  'case_consultation': 'Case Consultation',
  'case_inquiry': 'Case inquiry',
  'consultation_sub': 'Your opinion is the driving force for us to move forward',
  'case_type': 'Case Type',
  'select_scam_type': 'Select scam type',
  'your_case': 'Your Case',
  'please_enter_feedback': 'Please enter Your case feedback',
  'your_phone': 'Your mobile phone number',
  'please_enter_your_phone': 'Please enter your phone number',
  'submit_click': 'Submit with one click',
  'please_enter_phone': 'Please enter phone number',
  'search': 'search',
  'no_relevant': 'No relevant reply found',
  'success_title': 'Classic success stories',
  'bot_tele': 'Telephone',
  'bot_address': 'Address',
  'bot_cooperation': 'Cooperation authorization agency',
  'quick_links': 'Quick Links',
  'CFTC': 'International financial regulators including the Commodity and Futures Trading Commission （CFTC）',
  'FINRA': 'FINRA（FINRA）',
  'FINMA': 'Swiss Financial Market Supervisory Authority（FINMA） ',
  'FCA': 'UK Financial Conduct Authority（FCA）',
  'HKMA': 'Hong Kong Monetary Authority（HKMA）',
  'CFPB': 'Consumer Financial Protection Bureau（CFPB）',
  'case_option1': 'Internet Fundraising Scam',
  'case_option2': 'Internet stock fund investment fraud',
  'case_option3': 'Cryptocurrency Scam',
  'case_option4': 'love scam',
  'case_option5': 'e-commerce fraud',
  'case_option6': 'Part time scam',
  'case_option7': 'Other scams',
  'case_detail': 'Case details',
  'reply_message': 'Reply message',
  'submission_time': 'Submission time',
  ["home_service"]: "Customer Service",
}