export default {
  "menu_index": "Inicio",
  "menu_about": "Sobre nosotros",
  "menu_team": "Equipo profesional",
  "menu_business": "Ámbito de negocio",
  "menu_case": "Envío de casos",
  "menu_info": "Información legal",
  "menu_success": "Casos de éxito",
  "menu_language": "Cambiar idioma",
  "Integrity": "Integridad",
  "major": "Importante",
  "responsibility": "Responsabilidad",
  "efficiency": "Eficiencia",
  "index_intro1": "Estamos proporcionando a nuestros clientes",
  "index_intro2": "con una gama completa de servicios legales basados en",
  "index_intro2_orange": "Profesionalismo",
  "index_intro3_orange": "eficiencia",
  "index_intro3_white": "y",
  "index_intro3_orange2": "innovación",
  "elite_professional": "Despacho de abogados profesional de élite",
  "get_in_touch": "Contacta con nosotros",
  "about_sub": "SOBRE NOSOTROS",
  "law_name": "KIRKLAND&ELLIS LLP",
  "about_intro1": "Nuestro equipo de abogados tiene experiencia en el manejo de casos de fraude, rastreo y recuperación de activos, ayudando a nuestros clientes a recuperar activos y dinero perdido como resultado de fraude, mentiras o deshonestidad. Desarrollamos estrategias efectivas para rastrear activos.",
  "about_intro2": "Nos especializamos en el tratamiento de delitos relacionados con la ciberdelincuencia, incluyendo estafas de inversión en fondos compartidos, estafas de criptomonedas, estafas sentimentales, estafas de trabajos a tiempo parcial y más.",
  "about_intro3": "En particular, tratamos con delitos informáticos relacionados con fraude de fondos, malversación de dinero, fraude social y delitos a tiempo parcial.",
  "year": "AÑO",
  "established": "Establecido",
  "number_of_customers_served": "Número de clientes atendidos",
  "service_case": "Caso de servicio",
  "people": "Personas",
  "senior_consultant": "Consultor senior",
  "professional_body": "Cuerpo profesional",
  "team_sub": "EQUIPO",
  "anti_fraud_information": "Información contra el fraude",
  "anti_sub": "Noticias e información",
  "more": "MÁS",
  "swiper_text1": "Integridad, profesionalismo, Responsabilidad, Eficiencia",
  "swiper_text2": "El KIRKLAND&ELLIS LLP se compromete a",
  "swiper_text3": "Construir un bufete de abogados de alta calidad y profesional",
  "professional_team": "Equipo profesional",
  "business_tip": "La honestidad y la confiabilidad, la diligencia y la responsabilidad son los fundamentos del KIRKLAND&ELLIS LLP; ser pragmático y respetar la ley y lograr objetivos son la búsqueda incesante del KIRKLAND&ELLIS LLP",
  "scope1": "Estafa de inversión",
  "scope2": "Disputas económicas",
  "scope3": "Asuntos legales corporativos",
  "scope4": "Abogado privado",
  "scope5": "Litigios financieros",
  "scope6": "Ley antimonopolio",
  "scope7": "Recuperación de deudas",
  "scope8": "Otros negocios",
  "case_consultation": "Consulta de casos",
  "case_inquiry": "Consulta de casos",
  "consultation_sub": "Su opinión es la fuerza impulsora para que avancemos",
  "case_type": "Tipo de caso",
  "select_scam_type": "Seleccione el tipo de estafa",
  "your_case": "Su caso",
  "please_enter_feedback": "Por favor ingrese su retroalimentación de caso",
  "your_phone": "Su número de teléfono móvil",
  "please_enter_your_phone": "Por favor ingrese su número de teléfono",
  "submit_click": "Enviar con un clic",
  "please_enter_phone": "Por favor ingrese un número de teléfono",
  "search": "Buscar",
  "no_relevant": "No se encontró ninguna respuesta relevante",
  "success_title": "Historias de éxito clásicas",
  "bot_tele": "Teléfono",
  "bot_address": "Dirección",
  "bot_cooperation": "Agencia de autorización de cooperación",
  "quick_links": "Enlaces rápidos",
  "CFTC": "Reguladores financieros internacionales, incluida la Comisión de Comercio de Productos Básicos y Futuros （CFTC）",
  "FINRA": "FINRA (FINRA)",
  "FINMA": "Autoridad Supervisora del Mercado Financiero Suizo (FINMA)",
  "FCA": "Autoridad de Conducta Financiera del Reino Unido (FCA)",
  "HKMA": "Autoridad Monetaria de Hong Kong (HKMA)",
  "CFPB": "Oficina de Protección Financiera del Consumidor (CFPB) de los Estados Unidos",
  "case_option1": "Estafa de recaudación de fondos en Internet",
"case_option2": "Fraude de inversión en fondos de acciones por Internet",
"case_option3": "Estafa de criptomonedas",
"case_option4": "Estafa amorosa",
"case_option5": "Fraude de comercio electrónico",
"case_option6": "Estafa de trabajo a tiempo parcial",
"case_option7": "Otras estafas",
"case_detail": "Detalles del caso",
"reply_message": "Mensaje de respuesta",
"submission_time": "Hora de envío",
["home_service"]: "Servicio en línea",
}