export default {
  "menu_index": "首页",
  "menu_about": "关于我们",
  "menu_team": "专业团队",
  "menu_business": "业务范围",
  "menu_case": "案例提交",
  "menu_info": "法律信息",
  "menu_success": "成功案例",
  "menu_language": "语言切换",
  "Integrity": "诚信",
  "major": "专业",
  "responsibility": "责任",
  "efficiency": "效率",
  "index_intro1": "我们以",
  "index_intro2": "基于",
  "index_intro2_orange": "专业，诚信，",
  "index_intro3_orange": "高效，创新",
  "index_intro3_white": "的精神为基础,",
  "index_intro3_orange2": "致力于为客户提供全方位的法律服务。",
  "elite_professional": "精英专业律所",
  "get_in_touch": "联系我们",
  "about_sub": "关于我们",
  "law_name": "KIRKLAND&ELLIS LLP",
  "about_intro1": "我们的律师团队在处理欺诈、资产追踪和追回案件方面经验丰富，协助客户追回因欺诈、谎言或不诚实而丢失的资产和金钱。我们制定有效的策略来追踪资产。",
  "about_intro2": "我们专注于处理与网络犯罪相关的案件，包括股份基金投资诈骗、加密货币诈骗、感情诈骗、兼职工作诈骗等。",
  "about_intro3": "特别是，我们处理与资金诈骗、挪用资金、社会欺诈和兼职犯罪相关的计算机犯罪。",
  "year": "年",
  "established": "成立",
  "number_of_customers_served": "服务客户数量",
  "service_case": "服务案例",
  "people": "人",
  "senior_consultant": "资深顾问",
  "professional_body": "专业机构",
  "team_sub": "团队",
  "anti_fraud_information": "反欺诈信息",
  "anti_sub": "新闻和信息",
  "more": "更多",
  "swiper_text1": "诚信、专业、责任、效率",
  "swiper_text2": "KIRKLAND&ELLIS LLP致力于",
  "swiper_text3": "建立一个高质量和专业的律所",
  "professional_team": "专业团队",
  "business_tip": "诚实守信、勤奋尽责是KIRKLAND&ELLIS LLP的基石；务实守法、达成目标是KIRKLAND&ELLIS LLP不懈追求的目标",
  "scope1": "投资诈骗",
  "scope2": "经济纠纷",
  "scope3": "公司法律事务",
  "scope4": "私人律师",
  "scope5": "金融诉讼",
  "scope6": "反垄断法",
  "scope7": "债务收集",
  "scope8": "其他业务",
  "case_consultation": "案例咨询",
  "case_inquiry": "案例查询",
  "consultation_sub": "您的意见是我们前进的动力",
  "case_type": "案例类型",
  "select_scam_type": "选择诈骗类型",
  "your_case": "您的案件",
  "please_enter_feedback": "请输入您的案件反馈",
  "your_phone": "您的手机号",
  "please_enter_your_phone": "请输入您的手机号",
  "submit_click": "一键提交",
  "please_enter_phone": "请输入手机号",
  "search": "搜索",
  "no_relevant": "未找到相关回复",
  "success_title": "经典成功案例",
  "bot_tele": "电话",
  "bot_address": "地址",
  "bot_cooperation": "合作授权机构",
  "quick_links": "快速链接",
  "CFTC": "国际金融监管机构包括商品与期货交易委员会 （CFTC）",
  "FINRA": "美国金融业监管局（FINRA）",
  "FINMA": "瑞士金融市场监督管理局（FINMA）",
  "FCA": "英国金融行为监管局（FCA）",
  "HKMA": "香港金融管理局（HKMA）",
  "CFPB": "美国消费者金融保护局（CFPB）",
  "case_option1": "互联网募捐诈骗",
  "case_option2": "互联网股票基金投资诈骗",
  "case_option3": "加密货币诈骗",
  "case_option4": "情感诈骗",
  "case_option5": "电子商务诈骗",
  "case_option6": "兼职诈骗",
  "case_option7": "其他诈骗",
  "case_detail": "案例细节",
"reply_message": "回复消息",
"submission_time": "提交时间",
["home_service"]: '在线客服',
}