export default {
  "menu_index": "Índice",
  "menu_about": "Sobre nós",
  "menu_team": "Equipe profissional",
  "menu_business": "Escopo de negócios",
  "menu_case": "Envio de casos",
  "menu_info": "Informações legais",
  "menu_success": "Caso de sucesso",
  "menu_language": "Troca de idioma",
  "Integrity": "Integridade",
  "major": "Importante",
  "responsibility": "Responsabilidade",
  "efficiency": "Eficiência",
  "index_intro1": "Estamos fornecendo aos nossos clientes",
  "index_intro2": "uma ampla gama de serviços jurídicos com base em",
  "index_intro2_orange": "Profissionalismo",
  "index_intro3_orange": "eficiência",
  "index_intro3_white": " e",
  "index_intro3_orange2": " inovação.",
  "elite_professional": "Escritório de advocacia de elite profissional",
  "get_in_touch": "Contate-nos",
  "about_sub": "SOBRE NÓS",
  "law_name": "KIRKLAND&ELLIS LLP",
  "about_intro1": "Nossa equipe de advogados é experiente em lidar com fraudes, rastreamento de ativos e casos de recuperação, auxiliando nossos clientes a recuperar ativos e dinheiro perdido como resultado de fraude, mentiras ou desonestidade. Desenvolvemos estratégias eficazes para rastrear ativos.",
  "about_intro2": "Somos especializados em lidar com crimes relacionados à internet, incluindo golpes de investimento em fundos de ações, golpes de criptomoeda, golpes de relacionamento, golpes de emprego parcial e muito mais.",
  "about_intro3": "Em particular, lidamos com crimes cibernéticos relacionados a fraudes em fundos, desvio de dinheiro, fraude social e crime em tempo parcial.",
  "year": "Ano",
  "established": "Estabelecido",
  "number_of_customers_served": "Número de clientes atendidos",
  "service_case": "Caso de serviço",
  "people": "Pessoas",
  "senior_consultant": "Consultor sênior",
  "professional_body": "Órgão profissional",
  "team_sub": "EQUIPE",
  "anti_fraud_information": "Informações Anti-Fraude",
  "anti_sub": "Notícias e informações",
  "more": "MAIS",
  "swiper_text1": "Integridade, profissionalismo, responsabilidade, eficiência",
  "swiper_text2": "O KIRKLAND&ELLIS LLP está comprometido com",
  "swiper_text3": "Construir um escritório de advocacia de alta qualidade e profissional",
  "professional_team": "Equipe profissional",
  "business_tip": "Honestidade e confiabilidade, diligência e conscienciosidade são os fundamentos do KIRKLAND&ELLIS LLP; ser pragmático e respeitar a lei e alcançar objetivos são a busca incessante do KIRKLAND&ELLIS LLP",
  "scope1": "Golpe de investimento",
  "scope2": "Disputas econômicas",
  "scope3": "Assuntos legais corporativos",
  "scope4": "Advogado privado",
  "scope5": "Litígios financeiros",
  "scope6": "Lei antitruste",
  "scope7": "Cobrança de dívidas",
  "scope8": "Outros negócios",
  "case_consultation": "Consulta de caso",
  "case_inquiry": "Consulta de caso",
  "consultation_sub": "Sua opinião é a força motriz para avançarmos",
  "case_type": "Tipo de caso",
  "select_scam_type": "Selecione o tipo de golpe",
  "your_case": "Seu caso",
  "please_enter_feedback": "Por favor, digite o feedback do seu caso",
  "your_phone": "Seu número de telefone celular",
  "please_enter_your_phone": "Por favor, insira seu número de telefone",
  "submit_click": "Enviar com um clique",
  "please_enter_phone": "Por favor, insira o número de telefone",
  "search": "pesquisa",
  "no_relevant": "Nenhuma resposta relevante encontrada",
  "success_title": "Histórias de sucesso clássicas",
  "bot_tele": "Telefone",
  "bot_address": "Endereço",
  "bot_cooperation": "Agência de autorização de cooperação",
  "quick_links": "Links rápidos",
  "CFTC": "Reguladores financeiros internacionais, incluindo a Commodity and Futures Trading Commission (CFTC)",
  "FINRA": "FINRA (FINRA)",
  "FINMA": "Autoridade Supervisora do Mercado Financeiro Suíço (FINMA)",
  "FCA": "Autoridade de Conduta Financeira do Reino Unido (FCA)",
  "HKMA": "Autoridade Monetária de Hong Kong (HKMA)",
  "CFPB": "Bureau de Proteção Financeira ao Consumidor (CFPB)",
  "case_option1": "Golpe de arrecadação de fundos na internet",
  "case_option2": "Fraude de investimento em fundos de ações na internet",
  "case_option3": "Golpe de criptomoeda",
  "case_option4": "Golpe de amor",
  "case_option5": "Fraude eletrônica",
  "case_option6": "Golpe de meio período",
  "case_option7": "Outros golpes",
  "case_detail": "Detalhes do caso",
  "reply_message": "Mensagem de resposta",
  "submission_time": "Tempo de envio",
  "home_service": "Atendimento ao Cliente"
}
