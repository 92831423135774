<template>
  <div class="topmenu-container">
    <div class="top-info">
      <div class="top-info-item">
        <img src="../../assets/phone.svg" alt="" />{{ configInfo.phone }}
      </div>
      <div class="top-info-item">
        {{ configInfo.email }}
        <img src="../../assets/time.svg" alt="" class="time" />
      </div>
      <div class="top-info-item">24 hours 7 days a week Emergency Bailout</div>
    </div>
    <div class="top-menu">
      <div class="top-menu-logo">
        <img :src="configInfo.site_logo" alt="" />
        <span
          >KIRKLAND&ELLIS LLP</span
        >
      </div>
      <div class="top-menu-cfpb">
        <img src="../../assets/cfpb.png" alt="" />
      </div>
      <div class="top-menu-opera">
        <div class="opera-item" @click="routerChange('/index')">
          {{ $t("menu_index") }}
        </div>
        <div class="opera-item" @click="routerChange('/about')">
          {{ $t("menu_about") }}
        </div>
        <div class="opera-item" @click="routerChange('/team')">
          {{ $t("menu_team") }}
        </div>
        <div class="opera-item" @click="routerChange('/business')">
          {{ $t("menu_business") }}
        </div>
        <div class="opera-item" @click="routerChange('/case')">
          {{ $t("menu_case") }}
        </div>
        <div class="opera-item" @click="routerChange('/news')">
          {{ $t("menu_info") }}
        </div>
        <div class="opera-item" @click="routerChange('/success-case')">
          {{ $t("menu_success") }}
        </div>
        <div class="opera-item">
          <el-dropdown @command="changeLanguage">
            <span class="el-dropdown-link">{{ $t("menu_language") }}</span>
            <el-dropdown-menu slot="dropdown" class="languagedrop">
              <el-dropdown-item
                :command="item.key"
                v-for="item in languageList"
                :key="item.key"
                :class="$i18n.locale == item.key ? 'border' : ''"
                ><span>{{ item.title }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configInfo: "",
      languageList: [],
    };
  },
  async created() {
    await this.getLanguage();
    await this.getConfig();
  },
  methods: {
    routerChange(url) {
      this.$router.replace(url);
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.setLanguage(language);
    },
    async getConfig() {
      const res = await this.$api.getConfig();
      if (res.code == 200) {
        this.configInfo = res.data;
      }
    },
    async getLanguage() {
      const res = await this.$api.getLanguage();
      if (res.code == 200) {
        this.languageList = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dropdown-menu {
  background: #26303c;
  box-shadow: 0px 1px 7px 0px rgba(178, 166, 166, 0.35);
  border-radius: 4px;
}
.languagedrop {
  background: #26303c;
  box-shadow: 0px 1px 7px 0px rgba(178, 166, 166, 0.35);
  border-radius: 4px;
  border: none;
  color: #fff;
  li {
    color: #fff;
    line-height: 1;
    padding: 15px 35px;
    font-size: 16px;
    box-sizing: border-box;
    &:hover {
      color: #e6936b;
      background: #26303c;
    }
  }
  .border {
    border-left: 5px solid #f88505;
    color: #f88505 !important;
    box-sizing: border-box;
  }
}

.topmenu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .top-info {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f88505;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 15px;
      img {
        width: 30px;
        height: 30px;
      }
      .time {
        margin-left: 10px;
      }
    }
  }
  .top-menu {
    height: 130px;
    width: 100%;
    background: #26303c;
    z-index: 9999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &-logo {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 10px;
      line-height: 1.5;
      img {
        width: 60px;
        margin-right: 5px;
      }
    }
    &-cfpb {
      img {
        height: 70px;
      }
    }
    &-opera {
      display: flex;
      align-items: center;
      .opera-item {
        padding: 0 15px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        /deep/ .el-dropdown {
          color: #fff;
          &:hover {
            color: #e6936b;
          }
        }
        &:hover {
          color: #e6936b;
        }
      }
    }
  }
}
</style>